import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import ContactBar from "../components/ContactBar";
import SingleImage from "../library/SingleImage";
import BookNow from "../components/BookNow";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Layout from "../components/Layout";

const AnwendungenPage = (props) => (
  <Layout location={props.location}>
    <div id="angebot" className="page">
      <Helmet>
        <title>Indikation</title>
        <meta
          name="description"
          content="TCM Anwendungen, Indikation, Kinderwunsch, Endometriose, PCO Syndrom, Pathologisches Spermiogramm, Schwangerschaftsbeschwerden, Geburtsvorbereitende Akupunktur, Geburtsvorbereitung, Menstruationsbeschwerden"
        />
        <meta property="og:title" content="Indikation" />
        <meta
          property="og:description"
          content="Kinderwunsch, Endometriose, PCO Syndrom, Pathologisches Spermiogramm, Schwangerschaftsbeschwerden, Geburtsvorbereitende Akupunktur, Geburtsvorbereitung, Menstruationsbeschwerden"
        />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Indikation</h1>
        <ul className="offerList">
          <li>
            <Link to="/anwendungen/menstruationsstoerungen/">
              <SingleImage
                title="Menstruations-Beschwerden"
                image={props.data.menstruationsstoerungen.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/kinderwunsch/">
              <SingleImage
                title="Kinderwunsch"
                image={props.data.kinderwunsch.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/schwangerschaftsbeschwerden/">
              <SingleImage
                title="Schwangerschafts-Beschwerden"
                image={props.data.schwangerschaftsbeschwerden.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/geburtakupunktur/">
              <SingleImage
                title="Geburtsvorbereitung"
                image={props.data.geburtakupunktur.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/wochenbett/">
              <SingleImage
                title="Wochenbett"
                image={props.data.wochenbett.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/pcosyndrom/">
              <SingleImage
                title="PCO Syndrom"
                image={props.data.pcosyndrom.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/endometriose/">
              <SingleImage
                title="Endometriose"
                image={props.data.endometriose.fluid}
              />
            </Link>
          </li>
          <li>
            <Link to="/anwendungen/spermiogramm/">
              <SingleImage
                title="Pathologisches Spermiogramm"
                image={props.data.spermiogramm.fluid}
              />
            </Link>
          </li>
        </ul>
      </div>
      <BookNow />
      <Contact />
      <Footer />
    </div>
  </Layout>
);

export default AnwendungenPage;

export const query = graphql`
  query AnwedungenImagesQuery {
    kinderwunsch: imageSharp(fluid: { originalName: { regex: "/kiwu/" } }) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    endometriose: imageSharp(
      fluid: { originalName: { regex: "/endometriose/" } }
    ) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    pcosyndrom: imageSharp(fluid: { originalName: { regex: "/pco/" } }) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    spermiogramm: imageSharp(
      fluid: { originalName: { regex: "/spermiogramm/" } }
    ) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    schwangerschaftsbeschwerden: imageSharp(
      fluid: { originalName: { regex: "/schwangerschaft/" } }
    ) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    geburtakupunktur: imageSharp(
      fluid: { originalName: { regex: "/geburt/" } }
    ) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    menstruationsstoerungen: imageSharp(
      fluid: { originalName: { regex: "/menstruation/" } }
    ) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
    wochenbett: imageSharp(fluid: { originalName: { regex: "/wochenbett/" } }) {
      fluid(maxHeight: 600, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
